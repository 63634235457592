<template>
  <div class="mb-20">
    <div>
      <div class="flex flex-row">
        <img
          src="/content/images/illustrations/site-and-business.png"
          alt="site-and-business"
          class="mr-8 mt-4 mb-auto"
        />
        <p class="py-8 my-auto">
          For better results and improved campaign performance, complete the
          <span class="font-bold"> Core Business Description</span> and <span class="font-bold"> Industry </span>fields.
          <br />
          <br />
          Note: Fields below have to be submitted in the Main Language selected.
        </p>
      </div>
      <div v-if="pageLoading || !site">
        <BrightbidLoader />
      </div>
      <div
        v-else
        class="max-w-150"
      >
        <form
          class="mt-10 px-6"
          @keydown.prevent.enter="submit"
        >
          <div class="space-y-6">
            <div>
              <p class="h4">Account & Business description</p>
              <p class="text-bb-text-secondary p1">
                Provide details for Core Business Description, Industry and Business type to refine targeting and boost
                ad effectiveness.
              </p>
            </div>
            <text-area
              v-model="siteForm.description"
              :disabled="isLoading"
              class="w-full"
              label="Core business description"
              sub-label="The main products or services the company offers."
              input-type="text"
              input-name="name"
              input-id="name"
              tooltip
              tooltip-theme="lg"
              placeholder="eg. Ikea: Store that sells affordable furniture and home goods"
              :rows="4"
              :max-length="inputLengths.description.max"
              :min-length="inputLengths.description.min"
              :error-message="businessDescriptionErrorMessage"
              @focusout="$v.siteForm.description.$touch"
            >
              <template #tooltip-content>
                <span class="font-medium mb-2">Example of business descriptions:</span>
                <div class="flex flex-col gap-2 mt-2">
                  <p>
                    <span class="font-bold text-bb-green-accepted">Good: </span>“Store that sells affordable furniture
                    and home goods.”
                  </p>
                  <p>
                    <span class="font-bold text-bb-warning-200">Bad: </span>“IKEA is a global home furnishings retailer
                    known for its affordable and stylish furniture, as well as a wide range of home accessories and
                    decor. The company follows a self-assembly model, enabling cost-effective production and efficient
                    customer transportation. IKEA is renowned for its Scandinavian design influence and commitment to
                    sustainability.”
                  </p>
                  <p>
                    <span class="font-bold text-bb-error">Very Bad: </span>“Transform your living spaces with IKEA’s
                    innovative and affordable furniture solutions. Elevate your style, simplify your life – because at
                    IKEA, we believe in creating homes that reflect who you are.”"
                  </p>
                </div>
              </template>
            </text-area>
            <text-area
              v-model="siteForm.industry"
              :disabled="isLoading"
              class="w-full"
              label="Industry"
              sub-label="The industry in which the account operates in."
              input-type="text"
              input-name="name"
              input-id="name"
              tooltip
              tooltip-theme="lg"
              placeholder="Examples: “IT services”, “Healthcare”, “Banking”, “Gaming” e.t.c."
              :rows="4"
              :max-length="inputLengths.industry.max"
              :min-length="inputLengths.industry.min"
              :error-message="industryDescriptionErrorMessage"
              @focusout="$v.siteForm.industry.$touch"
            >
              <template #tooltip-content>
                <span class="font-medium mb-2"
                  >Please describe which industries your company operates in, add them as words separated by space and
                  commas</span
                >
              </template>
            </text-area>
            <div>
              <p class="h5">Business</p>
              <p class="text-bb-text-secondary p1">The kind of business the account does.</p>
            </div>
            <div class="flex flex-row gap-10">
              <div class="flex flex-row gap-2">
                <check-box
                  v-model="isB2B"
                  input-name="b2b"
                  input-id="b2b"
                />
                <p class="text-bb-text-secondary">B2B</p>
              </div>
              <div class="flex flex-row gap-2">
                <check-box
                  v-model="isB2C"
                  input-name="b2c"
                  input-id="b2c"
                />
                <p class="text-bb-text-secondary">B2C</p>
              </div>
            </div>

            <feedback-message
              v-if="$v.siteForm.target.$error"
              :message="'Please select at least one target'"
            />

            <div>
              <p class="h5 py-4">Monthly Targets</p>
            </div>
            <div class="space-y-16">
              <div class="flex items-center justify-between">
                <div class="w-96 space-y-4">
                  <div class="flex">
                    <template>
                      <ic-target-budget
                        :size="24"
                        class="pr-1"
                      />
                    </template>
                    <div class="cursor-pointer bb-text-headers font-medium px-3 flex">Spend Target</div>
                  </div>
                  <p class="text-bb-text-secondary">{{ targetGoalsInfo.spend }}</p>
                </div>
                <text-input
                  v-model="siteForm.siteMetricTarget.spend"
                  class="w-fit bb-text-secondary-gray pNumLarge"
                  input-type="text"
                  input-name="targetGoalSpend"
                  input-id="targetGoalSpend"
                  placeholder="N/A"
                  :error-message="$v.siteForm.siteMetricTarget.spend.$error ? 'Please enter a numeric value' : ''"
                  @focusout="$v.siteForm.siteMetricTarget.spend.$touch"
                >
                  <template #suffix>
                    <div class="absolute right-0 top-0 z-10 h-10 p-2 text-bb-disabled-gray">SEK</div>
                  </template>
                </text-input>
              </div>

              <div class="flex items-center justify-between">
                <div class="w-96 space-y-4">
                  <select-dropdown
                    :selection-value="customerTargetGoal"
                    :title="''"
                    :options="targetGoals"
                    @select-item="changeTargetGoal"
                  >
                    <template #icon>
                      <ic-target-cpa
                        v-if="selectedTargetGoalType == 'cpa'"
                        :size="24"
                        class="pr-1 text-bb-brand-purple"
                      />
                      <ic-target-roi
                        v-if="selectedTargetGoalType == 'roas'"
                        :size="24"
                        class="pr-1 text-bb-brand-purple"
                      />
                    </template>
                  </select-dropdown>
                  <p
                    v-if="selectedTargetGoalType == 'cpa'"
                    class="text-bb-text-secondary p1"
                  >
                    {{ targetGoalsInfo.cpa }}
                  </p>
                  <p
                    v-else
                    class="text-bb-text-secondary"
                  >
                    {{ targetGoalsInfo.roas }}
                  </p>
                </div>
                <div class="relative">
                  <text-input
                    v-if="selectedTargetGoalType == 'cpa'"
                    v-model="siteForm.siteMetricTarget.cpa"
                    class="w-fit bb-text-secondary-gray pNumLarge"
                    input-type="text"
                    input-name="targetGoalCpa"
                    input-id="targetGoalCpa"
                    placeholder="N/A"
                    :error-message="$v.siteForm.siteMetricTarget.cpa.$error ? 'Please enter a numeric value' : ''"
                    @focusout="$v.siteForm.siteMetricTarget.cpa.$touch"
                  >
                    <template #suffix>
                      <div class="absolute right-0 top-0 z-10 h-10 p-2 text-bb-disabled-gray">SEK</div>
                    </template>
                  </text-input>
                  <text-input
                    v-else
                    v-model="siteForm.siteMetricTarget.roas"
                    class="w-fit bb-text-secondary-gray pNumLarge"
                    input-type="text"
                    input-name="targetGoalRoas"
                    input-id="targetGoalRoas"
                    placeholder="N/A"
                    :error-message="$v.siteForm.siteMetricTarget.roas.$error ? 'Please enter a numeric value' : ''"
                    @focusout="$v.siteForm.siteMetricTarget.roas.$touch"
                  >
                    <template #suffix>
                      <div class="absolute right-0 top-0 z-10 h-10 p-2 text-bb-disabled-gray">%</div>
                    </template>
                  </text-input>
                </div>
              </div>
            </div>
            <div
              v-if="site.type === 'amazon'"
              class="pt-10"
            >
              <div class="col-span-2">
                <div class="pb-6 flex flex-row gap-2">
                  <div class="w-8">
                    <img
                      src="/content/images/amazon-icon.svg"
                      alt=""
                    />
                  </div>
                  <p class="h4">Amazon</p>
                </div>
                <div class="flex flex-row">
                  <div class="flex flex-col">
                    <p class="p1">Shipping Cost</p>
                    <p class="text-bb-text-secondary">
                      Will be applied to all products in your product feed. For more customized shipping leave this
                      empty and add shipping info in Google Merchant Settings.
                    </p>
                  </div>
                  <text-input
                    class="max-w-150 w-full my-auto"
                    input-type="text"
                    input-name="amazonShipping"
                    input-id="amazonShipping"
                    placeholder="0"
                    padding-right="pr-10"
                  >
                    <template #suffix>
                      <div class="absolute right-0 top-0 z-10 h-10 p-2 text-bb-disabled-gray">SEK</div>
                    </template>
                  </text-input>
                </div>
              </div>
            </div>
            <div class="flex flex-row-reverse gap-4 pt-8">
              <merge-button-round
                :disabled="isLoading"
                button-type="primary"
                class="w-24"
                @click="submitForm"
              >
                <p>Save</p>
              </merge-button-round>
              <merge-button-round
                :disabled="isLoading"
                button-type="secondary"
                class="w-24"
                @click="populateForm"
              >
                <p>Cancel</p>
              </merge-button-round>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { decimal, maxLength, minLength, minValue, required } from 'vuelidate/lib/validators'
import TextInput from '@/components/input/brightbid/TextInput'
import TextArea from '@/components/input/brightbid/TextArea.vue'
import CheckBox from '@/components/input/brightbid/CheckBox'
import Toast from '@/components/shared/Toast'
import BrightbidLoader from '@/components/loader/BrightbidLoader'
import IcTargetBudget from '@/components/icon/ic-target-budget.vue'
import IcTargetCpa from '@/components/icon/ic-target-cpa.vue'
import IcTargetRoi from '@/components/icon/ic-target-roi.vue'
import SelectDropdown from '@/components/input/brightbid/SelectDropdown'
import FeedbackMessage from '@/components/base/FeedbackMessage.vue'

const TARGET_GOALS_OPTIONS = [
  { label: 'CPA Target', value: 'cpa' },
  { label: 'ROAS Target', value: 'roas' },
]
const TARGET_GOALS_INFO = {
  spend: 'This is used for reporting and monitoring only. Input what you wish to spend each month.',
  cpa: 'This is used for reporting and monitoring only. Input what you wish to have in Cost Per Acquisition each month.',
  roas: 'This is used for reporting and monitoring only. Input what you wish to have in Return On Ad Spend each month.',
}

export default {
  name: 'SettingsForm',
  components: {
    FeedbackMessage,
    TextInput,
    TextArea,
    CheckBox,
    BrightbidLoader,
    IcTargetBudget,
    IcTargetCpa,
    IcTargetRoi,
    SelectDropdown,
  },
  props: {
    site: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pageLoading: false,
      isLoading: false,
      siteForm: {
        description: null,
        target: [],
        industry: null,
        type: null,
        siteMetricTarget: {
          spend: null,
          cpa: null,
          roas: null,
        },
      },
      inputLengths: {
        description: {
          min: 25,
          max: 150,
        },
        industry: {
          min: 5,
          max: 30,
        },
      },
      targetGoals: TARGET_GOALS_OPTIONS,
      targetGoalsInfo: TARGET_GOALS_INFO,
      customerTargetGoal: TARGET_GOALS_OPTIONS[0],
      selectedTargetGoalType: '',
    }
  },
  computed: {
    isB2B: {
      get() {
        return this.siteForm.target.includes('b2b')
      },
      set(value) {
        this.toggleCheckBox('b2b', value)
      },
    },
    isB2C: {
      get() {
        return this.siteForm.target.includes('b2c')
      },
      set(value) {
        this.toggleCheckBox('b2c', value)
      },
    },
    businessDescriptionErrorMessage() {
      const lengths = this.inputLengths.description
      if (!this.$v.siteForm.description.$dirty) return null
      if (!this.$v.siteForm.description.required) return 'Description is required'
      if (!this.$v.siteForm.description.minLength) return `Please use at least ${lengths.min} characters.`
      if (!this.$v.siteForm.description.maxLength) return `Please use at most ${lengths.max} characters.`
      return null
    },
    industryDescriptionErrorMessage() {
      const lengths = this.inputLengths.industry
      if (!this.$v.siteForm.industry.$dirty) return null
      if (!this.$v.siteForm.industry.required) return 'Industry is required'
      if (!this.$v.siteForm.industry.minLength) return `Please use at least ${lengths.min} characters.`
      if (!this.$v.siteForm.industry.maxLength) return `Please use at most ${lengths.max} characters.`
      return null
    },
  },
  watch: {
    site: {
      deep: true,
      async handler() {
        this.pageLoading = true
        await this.populateForm()
        this.pageLoading = false
      },
    },
  },
  validations() {
    const descriptionLengths = this.inputLengths.description
    const industryLengths = this.inputLengths.industry
    return {
      siteForm: {
        description: {
          required,
          minLength: minLength(descriptionLengths.min),
          maxLength: maxLength(descriptionLengths.max),
        },
        industry: {
          required,
          minLength: minLength(industryLengths.min),
          maxLength: maxLength(industryLengths.max),
        },
        target: { custom: this.hasAtLeastOneTarget },
        siteMetricTarget: {
          spend: { decimal, minValue: minValue(0) },
          cpa: { decimal, minValue: minValue(0) },
          roas: { decimal, minValue: minValue(0) },
        },
      },
    }
  },
  async mounted() {
    this.pageLoading = true
    await this.populateForm()
    this.pageLoading = false
  },
  methods: {
    async populateForm() {
      if (!this.site) return
      this.siteForm.description = this.site.description
      this.siteForm.industry = this.site.industry
      this.siteForm.target = this.site.target
      this.siteForm.type = this.site.type
      this.setupSiteMetricTarget()
      this.$v.$reset()
    },
    hasAtLeastOneTarget() {
      return this.siteForm.b2b || this.siteForm.b2c || this.siteForm.target.length > 0
    },
    toggleCheckBox(type, value) {
      if (value) {
        if (!this.siteForm.target.includes(type)) {
          this.siteForm.target.push(type)
        }
      } else {
        this.siteForm.target = this.siteForm.target.filter(item => item !== type)
      }
    },
    setupSiteMetricTarget() {
      const metricTarget = this.site.site_metric_target
      this.siteForm.siteMetricTarget = {
        spend: metricTarget?.spend ?? null,
        cpa: metricTarget?.cpa ?? null,
        roas: metricTarget?.roas ?? null,
      }

      this.customerTargetGoal = metricTarget?.roas
        ? { label: 'ROAS Target', value: 'roas' }
        : { label: 'CPA Target', value: 'cpa' }

      this.selectedTargetGoalType = this.customerTargetGoal.value
    },
    changeTargetGoal(item) {
      this.siteForm.siteMetricTarget.cpa = null
      this.siteForm.siteMetricTarget.roas = null
      this.selectedTargetGoalType = item.value
    },
    async requestSiteChanges() {
      const resp_business = await this.requestSiteBusinessChanges()

      if (!resp_business) {
        return false
      }

      const resp_metrics_target = await this.requestSiteMetricsChanges()

      if (!resp_metrics_target) {
        await this.requestSiteBusinessChanges(true)
        return false
      }

      return true
    },
    getRollbackPayload() {
      return {
        description: this.site.description,
        industry: this.site.industry,
        target: this.site.target,
        type: this.site.type,
      }
    },
    async requestSiteBusinessChanges(rollback = false) {
      try {
        const payload = rollback ? this.getRollbackPayload() : this.siteForm
        const resp = await this.$http.patch(`/common/site/${this.site.id}/business`, payload)
        return resp && resp.status === 200
      } catch (e) {
        return false
      }
    },
    async requestSiteMetricsChanges() {
      try {
        const payloadTargetGoal = Object.keys(this.siteForm.siteMetricTarget).reduce((item, key) => {
          item[key] = parseFloat(this.siteForm.siteMetricTarget[key])
          return item
        }, {})
        const resp = await this.$http.patch(`common/site/${this.site.id}/metric-targets`, payloadTargetGoal)

        return resp && resp.status === 200
      } catch (e) {
        return false
      }
    },
    async submitForm() {
      this.$v.$touch()

      if (this.$v.siteForm.$invalid) {
        return this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'One or more fields are not valid',
            type: 'error',
          },
        })
      }

      this.isLoading = true
      const resp = await this.requestSiteChanges()
      this.isLoading = false

      if (resp) {
        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success',
            message: 'Account has been updated!',
            type: 'success',
          },
        })
        this.isLoading = false
        this.$emit('site-updated')
      } else {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Could not save changes',
            type: 'error',
          },
        })
      }
    },
  },
}
</script>
