<template>
  <page>
    <page-section bottom-border>
      <page-title>
        <template #icon>
          <ic-cog :size="24" />
        </template>
        <template #default>Account Details</template>
      </page-title>
    </page-section>
    <div class="bottom-border">
      <div class="pt-6">
        <page-tabs
          :selected-tab="selectedTab"
          :tabs="tabs"
          @select-tab="
            tab => {
              selectedTab = tab
            }
          "
        />
      </div>
    </div>
    <page-section
      class="main-container base-scrollbar"
      bottom-border
    >
      <component
        :is="selectedTab"
        v-if="siteDetails"
        :site="siteDetails"
        @site-updated="loadSite"
      />
    </page-section>
  </page>
</template>

<script>
import IcCog from 'vue-material-design-icons/CogOutline'
import SiteInfoForm from '@/components/settings/SiteInfoForm'
import Page from '@/components/base/page/Page.vue'
import PageSection from '@/components/base/page/PageSection.vue'
import PageTabs from '@/components/shared/PageTabs'
import SiteAndBusinessForm from '@/components/settings/SiteAndBusinessForm'
import FeedTheAI from '@/components/settings/FeedTheAI'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'SiteSettings',
  components: { Page, PageSection, SiteInfoForm, IcCog, PageTabs, SiteAndBusinessForm, FeedTheAI },
  props: ['site'],
  data() {
    return {
      tabs: [
        { label: 'General Info', value: 'SiteInfoForm' },
        { label: 'Account & Business', value: 'SiteAndBusinessForm' },
        { label: 'Feed the AI', value: 'FeedTheAI' },
      ],
      selectedTab: 'SiteInfoForm',
    }
  },
  watch: {
    async selectedSite(newVal, oldVal) {
      if (newVal && newVal.value !== oldVal?.value) {
        this.pageLoading = true
        await this.fetchSiteDetails(newVal.value)
        this.pageLoading = false
      }
    },
  },
  computed: {
    ...mapState('site', ['selectedSite', 'siteDetails']),
  },
  methods: {
    ...mapActions('site', ['fetchSiteDetails']),
    loadSite() {
      this.fetchSiteDetails(this.selectedSite.value)
    },
  },
  async mounted() {
    this.selectedTab = this.$route.query.tab || 'SiteInfoForm'
    if (this.selectedSite) {
      await this.fetchSiteDetails(this.selectedSite.value)
    }
  },
}
</script>

<style lang="scss">
.main-container {
  overflow-y: auto;
  height: calc(100vh - 232px);
}
</style>
